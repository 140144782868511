import gtag from './analytics.js';

Spree.Customization.Google = {}
// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce

Spree.Customization.Google.impression = function () {
  if (window.ga_enabled && typeof gtag !== 'undefined' && document.getElementsByClassName('gaDataLayerProduct-js').length) {
    console.log('impression')
    var productsElements = document.getElementsByClassName('gaDataLayerProduct-js')
    var data = [...productsElements].map(function(el, index) {
      var parsed = JSON.parse(el.getAttribute('data-gaDataLayer'))
      parsed.list_position = index + 1;
      return parsed;
    })
    gtag('event', 'view_item_list', {
      'items': data
    });
  };
}

Spree.Customization.Google.productClick = function (product) {
  if (window.ga_enabled && typeof gtag !== 'undefined') {
    console.log('productClick')
    gtag('event', 'select_content', {
      'content_type': 'product',
      'items': [product]
    });
  }
}

Spree.Customization.Google.productDetails = function () {
  if (window.ga_enabled && typeof gtag !== 'undefined' && document.getElementById('product-details-js')) {
    console.log('productDetails')
    var product = JSON.parse(document.getElementById('product-details-js').getAttribute('data-gaDataLayer'))
    gtag('event', 'view_item', {
      'items': [product]
    });
  };
}

Spree.Customization.Google.addToCart = function (product) {
  if (window.ga_enabled && typeof gtag !== 'undefined') {
    console.log('addToCart')
    gtag('event', 'add_to_cart', {
      'items': product
    });
  }
}

Spree.Customization.Google.checkout = function () {
  if (window.ga_enabled && typeof gtag !== 'undefined' && document.getElementById('checkout_content-js')) {
    console.log('checkout')
    var data = JSON.parse(document.getElementById('checkout_content-js').getAttribute('data-gaDataLayer'))
    data.items = data.items.map(function(el) { return JSON.parse(el) })
    gtag('event', 'checkout_progress', data);
  };
}

Spree.Customization.Google.purchase = function () {
  if (window.ga_enabled && typeof gtag !== 'undefined' && document.getElementById('order_summary-js')) {
    console.log('purchase')
    var data = JSON.parse(document.getElementById('order_summary-js').getAttribute('data-gaDataLayer'))
    data.items = data.items.map(function(el) { return JSON.parse(el) })
    if (Spree.Customization.Google.getCookie('order_id_for_ga_purchase') !== data.transaction_id) {
      document.cookie = "order_id_for_ga_purchase=" + data.transaction_id + ";"
      gtag('event', 'purchase', data);
    }
  };
}

Spree.Customization.Google.getCookie = function(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

if (window.ga_enabled && typeof gtag !== 'undefined') {
  window.addEventListener('turbolinks:load', function() {
    Spree.Customization.Google.impression()
    Spree.Customization.Google.productDetails()
    Spree.Customization.Google.checkout()
    Spree.Customization.Google.purchase()
  })
}
