Spree.Customization.deliveryDate = {}

Spree.Customization.deliveryDate.initDeliveryDateLabelBtn = function(element) {
  if ($(element).hasClass('disabled-button-border')) return

  removeActive()
  $(element).addClass('active')
}

Spree.Customization.deliveryDate.initDeliveryDateCustomDateBtn = function(element) {
  if ($(element).hasClass('disabled-button-border')) return

  var value = $(element).data().value;
  const DELIVERY_DATE_SELECTOR = '#js-order_delivery_date'

  $(DELIVERY_DATE_SELECTOR).val(value);
}

Spree.Customization.deliveryDate.initDeliveryDateDatepicker = function() {
  const DELIVERY_DATE_PICKER_SELECTOR = '.order-delivery-date__datepicker-js'

  Spree.Customization.deliveryDate.picker = flatpickr(DELIVERY_DATE_PICKER_SELECTOR, {
    minDate: 'today',
    disableMobile: 'true',
    className: "flatpickr-day.selected",
    onChange: (dateObj, dateStr) => {
      const DELIVERY_DATE_SELECTOR = '#js-order_delivery_date'
      const DELIVERY_DATE_LABEL_TEXT_SELECTOR = '#order-delivery-date__label-btn-calendar-text'
      const DELIVERY_DATE_LABEL_CALENDAR_SELECTOR = '#order-delivery-date__label-btn-calendar'

      $(DELIVERY_DATE_SELECTOR).val(dateStr);
      removeActive()
      var label = dateObj[0].toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })
      $(DELIVERY_DATE_LABEL_TEXT_SELECTOR).text(label)
      $(DELIVERY_DATE_LABEL_CALENDAR_SELECTOR).addClass('active')
    }
  })
}

var removeActive = function() {
  const DELIVERY_DATE_BTN_SELECTOR = '.order-delivery-date__label-btn'

  $(DELIVERY_DATE_BTN_SELECTOR).each(function() {
    $(this).removeClass('active')
  })
}
