Spree.Customization.homepage = {}

Spree.Customization.homepage.initContactMapImage = function() {
  const PREVIEW_SELECTOR = '#contact_map_image-preview';
  const IFRAME_SELECTOR = '#contact_map_image-iframe';
  const IFRAME_BLOCK_SELECTOR = '#contact_map_image-iframe-block';
  const PREVIEW_IMAGE_HIDE_DELAY = 500;

  $(IFRAME_SELECTOR).attr('src', $(IFRAME_SELECTOR).attr('data-src'));
  $(PREVIEW_SELECTOR).fadeOut(PREVIEW_IMAGE_HIDE_DELAY, function() {
    $(IFRAME_BLOCK_SELECTOR).fadeIn()
  });
  $(IFRAME_SELECTOR).removeAttr('data-src');
}
