Spree.Customization.timer = {
  initialized: false,
  cutOffTimeObject: {},
  interval: null,
  test: {
    isTestMode: false,
    newDate: null,
  },
  SAME_DAY_SELECTOR: "#js-cut-off-times--same-day",
  NEXT_DAY_SELECTOR: "#js-cut-off-times--next-day",
  TIMER_HOUR_SELECTOR: "#js-cut-off-times--timer-remaining-hour",
  TIMER_MIN_SELECTOR: "#js-cut-off-times--timer-remaining-min",
  TIMER_SEC_SELECTOR: "#js-cut-off-times--timer-remaining-sec",
};

Spree.Customization.timer.initTimer = function () {
  const slug = `slug=${window.location.pathname}`;

  // disable order now button until available delivery dates are fetched
  const ORDER_NOW_SELECTOR = "#add-to-cart-button";
  $(ORDER_NOW_SELECTOR).prop('disabled', true)

  var query = window.location.search;
  if (!query) {
    query += `?${slug}`;
  } else {
    query += `&${slug}`;
  }
  fetch(`/cut_off_time${query}`, {
    headers: SpreeAPI.prepareHeaders({
      "X-Spree-Order-Token": SpreeAPI.orderToken,
    }),
  }).then(function (response) {
    response.json().then(function (cutOffTimeObject) {
      Spree.Customization.timer.timerObject = cutOffTimeObject.timer.map(
        (day) => day && new Date(day)
      );
      Spree.Customization.timer.deliveryDateObject =
        cutOffTimeObject.delivery_date_value.map((day) => day && new Date(day));
      Spree.Customization.timer.pickupDateObject =
        cutOffTimeObject.pickup_date_value.map((day) => day && new Date(day));
      Spree.Customization.deliveryDate.picker &&
        Spree.Customization.deliveryDate.picker.set(
          "enable",
          cutOffTimeObject.delivery_dates
        );
      Spree.Customization.pickupDate.picker &&
        Spree.Customization.pickupDate.picker.set(
          "enable",
          cutOffTimeObject.pickup_dates
        );
      Spree.Customization.timer.initialized = true;
      Spree.Customization.timer.tabs = [
        {
          selector: ".order-delivery-date__label-btn-date",
          object: Spree.Customization.timer.deliveryDateObject,
        },
        {
          selector: ".order-pickup-date__label-btn-date",
          object: Spree.Customization.timer.pickupDateObject,
        },
      ];
      initDeliveryButtons();
      $(ORDER_NOW_SELECTOR).prop('disabled', false)
    });
  });
};

Spree.Customization.timer.setDefaultButton = function (el, force = false) {
  const DELIVERY_DATE_SELECTOR = "#js-order_delivery_date";
  if (force || !$(DELIVERY_DATE_SELECTOR).val()) {
    $(el).each(function () {
      if (!$(this).hasClass("disabled")) {
        $(this).click();
        return false;
      }
    });
  }
};

Spree.Customization.timer.runTimer = function () {
  if (!Spree.Customization.timer.initialized) return;
  const currentDayCutOffTime = Spree.Customization.timer.timerObject[0];
  const nextAvailableDayCutOffTime = findNextAvailableDayCutOffTime();
  var cutOffDiff = getCutOffDiff(currentDayCutOffTime);
  var cutOffToday;

  if (cutOffDiff < 0) {
    cutOffDiff = getCutOffDiff(nextAvailableDayCutOffTime);
    cutOffToday = false;
  } else {
    cutOffToday = true;
  }

  showTimerLayout(cutOffToday, cutOffDiff);
  disableDeliveryButtons(cutOffToday);
};

const getCutOffDiff = function (cutOffTime) {
  if (!cutOffTime) return -1;
  const diff = parseInt((cutOffTime - newDate()) / 1000);
  return diff;
};

const findNextAvailableDayCutOffTime = function () {
  for (var i = 1; i < Spree.Customization.timer.timerObject.length; i++) {
    if (Spree.Customization.timer.timerObject[i]) {
      return Spree.Customization.timer.timerObject[i];
    }
  }
  return null;
};

const showTimerLayout = function (cutOffToday, secondsLeft) {
  if (cutOffToday) {
    $(Spree.Customization.timer.NEXT_DAY_SELECTOR).hide();
    $(Spree.Customization.timer.SAME_DAY_SELECTOR).show();
  } else {
    $(Spree.Customization.timer.SAME_DAY_SELECTOR).hide();
    $(Spree.Customization.timer.NEXT_DAY_SELECTOR).show();
  }
  if (secondsLeft < 0) {
    $(Spree.Customization.timer.TIMER_HOUR_SELECTOR).html("00");
    $(Spree.Customization.timer.TIMER_MIN_SELECTOR).html("00");
    $(Spree.Customization.timer.TIMER_SEC_SELECTOR).html("00");
    return;
  }

  var time = secondsLeft;
  var hoursleft = Math.floor(time / 3600);
  time = time - hoursleft * 3600;
  var minutesleft = Math.floor(time / 60);
  var secondsleft = time - minutesleft * 60;

  if (hoursleft < 10) hoursleft = "0" + hoursleft;
  if (minutesleft < 10) minutesleft = "0" + minutesleft;
  if (secondsleft < 10) secondsleft = "0" + secondsleft;

  $(Spree.Customization.timer.TIMER_HOUR_SELECTOR).html(hoursleft);
  $(Spree.Customization.timer.TIMER_MIN_SELECTOR).html(minutesleft);
  $(Spree.Customization.timer.TIMER_SEC_SELECTOR).html(secondsleft);
};

const newDate = function () {
  if (Spree.Customization.timer.test.isTestMode) {
    return Spree.Customization.timer.test.newDate;
  } else {
    return new Date();
  }
};

const initDeliveryButtons = function () {
  Spree.Customization.timer.tabs.forEach(function (el) {
    if (!$(el.selector)) return;
    const days = el.object.filter((e) => e).slice(0, 3);
    $(el.selector).each(function (index, btn) {
      $(btn).data("value", formatDay(days[index]));
      const label = formatLocaleDay(days[index]);
      $(btn).text(label);
    });
  });
};
const disableDeliveryButtons = function (cutOffToday) {
  const today = dayWithTz(new Date());
  Spree.Customization.timer.tabs.forEach(function (el) {
    if (!$(el.selector)) return;
    $(el.selector).each(function () {
      const that = this;
      const value = $(that).data().value;
      if (value === formatDay(today) && !cutOffToday) {
        $(that).addClass("disabled disabled-button-border");

        disablePickerDeliveryDate(value);
      } else {
        $(that).removeClass("disabled disabled-button-border");
      }
    });
  });
  Spree.Customization.timer.timerObject.forEach((day, index) => {
    if (index > 0) {
      // current day is already handled in disableDeliveryButtons func
      if (!day) {
        var dayToDisable = new Date(today);
        dayToDisable.setDate(dayToDisable.getDate() + index);
        disablePickerDeliveryDate(dayToDisable);
      }
    }
  });
  Spree.Customization.timer.setDefaultButton(
    ".order-delivery-date__label-btn-date"
  );
};
const disablePickerDeliveryDate = function (valueToDisable) {
  if (
    !Spree.Customization.deliveryDate.picker ||
    !Spree.Customization.deliveryDate.picker.config
  )
    return;
  const disabledDates = Spree.Customization.deliveryDate.picker.config.disable;
  disabledDates.push(valueToDisable);
  Spree.Customization.deliveryDate.picker.set("disable", disabledDates);
};
const dayWithTz = function (day) {
  const offset = day.getTimezoneOffset();
  return new Date(day.getTime() - offset * 60 * 1000);
};
const formatDay = function (day) {
  return day.toISOString().split("T")[0];
};
const formatLocaleDay = function (day) {
  return day.toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });
};
