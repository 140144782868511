Spree.Customization = {}

// require('./sentry');
require('./homepage');
require('./timer');
require('./timer_spec');
require('./delivery_date');
require('./pickup_date');
require('./checkout');
require('./google_events');
require('./ch_flow');
require('./review_payment');

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
