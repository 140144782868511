Spree.Customization.timer.runTests = function() {
  Spree.Customization.timer.test.isTestMode = true
  clearInterval(Spree.Customization.timer.interval)
  console.log('%cTimer interval is stopped!', 'color: yellow');
  console.log('Running tests...');

  test1()
  test2()
  test3()
  test4()
  test5()
  test6()

  console.log('Tests are finished');
}

var timeEqualTo = function(hour, min, sec) {
  var result = $(Spree.Customization.timer.TIMER_HOUR_SELECTOR).text() === hour &&
    $(Spree.Customization.timer.TIMER_MIN_SELECTOR).text() === min &&
    $(Spree.Customization.timer.TIMER_SEC_SELECTOR).text() === sec;

  return result
}

var layoutToday = function(isToday) {
  if (isToday) {
    return $(Spree.Customization.timer.SAME_DAY_SELECTOR).is(':visible')
  } else {
    return $(Spree.Customization.timer.NEXT_DAY_SELECTOR).is(':visible')
  }
}
var logResult = function(number, testType, result) {
  if (result) {
    console.log(`%c#${number} Test ${testType} - passed`, 'color: green');
  } else {
    console.log(`%c#${number} Test ${testType} - not passed`, 'color: red');
  }
}

var test1 = function() {
  var cutOffTimeObject = [
    "2020-10-27T18:00:00.000-04:00",
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '12', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('Same day delivery (before same day cut off)');
  logResult(1, 'time', timeEqualTo('10', '00', '00'))
  logResult(1, 'layout', layoutToday(true))
}
var test2 = function() {
  var cutOffTimeObject = [
    "2020-10-27T14:00:00.000-04:00",
    "2020-10-28T14:00:00.000-04:00",
    null,
    null,
    null,
    null,
    null,
    null
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '19', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('After same day cutoff');
  logResult(2, 'time', timeEqualTo('23', '00', '00'))
  logResult(2, 'layout', layoutToday(false))
}
var test3 = function() {
  var cutOffTimeObject = [
    "2020-10-27T14:00:00.000-04:00",
    null,
    "2020-10-29T14:00:00.000-04:00",
    null,
    null,
    null,
    null,
    null
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '19', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('After same day cutoff and no cutoff tomorrow');
  logResult(3, 'time', timeEqualTo('47', '00', '00'))
  logResult(3, 'layout', layoutToday(false))
}
var test4 = function() {
  var cutOffTimeObject = [
    "2020-10-27T14:00:00.000-04:00",
    null,
    null,
    "2020-10-30T14:00:00.000-04:00",
    null,
    null,
    null,
    null
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '19', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('No cutoff time for a few days');
  logResult(4, 'time', timeEqualTo('71', '00', '00'))
  logResult(4, 'layout', layoutToday(false))
}
var test5 = function() {
  var cutOffTimeObject = [
    "2020-10-27T14:00:00.000-04:00",
    null,
    null,
    null,
    null,
    null,
    null,
    "2020-11-03T14:00:00.000-04:00"
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '19', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('No cutoff time for a week');
  logResult(5, 'time', timeEqualTo('167', '00', '00'))
  logResult(5, 'layout', layoutToday(false))
}
var test6 = function() {
  var cutOffTimeObject = [
    null,
    "2020-10-28T14:00:00.000-04:00",
    null,
    null,
    null,
    null,
    null,
    null
  ]
  Spree.Customization.timer.initTimer(cutOffTimeObject);
  Spree.Customization.timer.test.newDate = new Date(Date.UTC('2020', '09', '27', '19', '00', '00'));
  Spree.Customization.timer.runTimer();
  console.log('No cutoff time today');
  logResult(6, 'time', timeEqualTo('23', '00', '00'))
  logResult(6, 'layout', layoutToday(false))
}
