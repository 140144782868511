Spree.Customization.checkout = {};

Spree.Customization.checkout.initDeliveryDateDatepicker = function (
  reloadPage = true
) {
  const DELIVERY_DATE_PICKER_SELECTOR = ".order-delivery-date__datepicker-js";
  const DELIVERY_DATE_SELECTOR = "#js-order_delivery_date";
  const DELIVERY_DATE_LABEL_SELECTOR =
    "#order-delivery-date__label-btn-calendar";
  const DELIVERY_DATE_LABEL_TEXT_SELECTOR =
    "#order-delivery-date__label-btn-calendar-text";

  Spree.Customization.checkout.picker = flatpickr(
    DELIVERY_DATE_PICKER_SELECTOR,
    {
      minDate: "today",
      disableMobile: "true",
      defaultDate: $(DELIVERY_DATE_SELECTOR).val(),
      enable: $(DELIVERY_DATE_LABEL_SELECTOR).data().delivery_dates,
      onChange: (dateObj, dateStr) => {
        $(DELIVERY_DATE_SELECTOR).val(dateStr);
        var label = dateObj[0].toLocaleDateString("en-US", {
          weekday: "long",
          month: "short",
          day: "numeric",
        });
        $(DELIVERY_DATE_LABEL_TEXT_SELECTOR).text(label);

        if (reloadPage) {
          var storeId = $("#js-store_pickup_id").val();
          window.location.href =
            window.location.pathname +
            "?delivery_date=" +
            dateStr +
            "&store_id=" +
            storeId;
        }
      },
    }
  );
};
