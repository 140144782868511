Spree.Customization.review_payment = {}

Spree.Customization.review_payment.initCollapseEvent = function() {
  $('#collapseContact').on('shown.bs.collapse', function () {
    $('#preview-email').hide()
    $('#preview-phone').hide()
  })
  $('#collapseContact').on('hidden.bs.collapse', function () {
    $('#preview-email').show()
    $('#preview-phone').show()
  })
  $('#collapseMessage').on('shown.bs.collapse', function () {
    $('#preview-occasion_text').hide()
    $('#preview-message').hide()
  })
  $('#collapseMessage').on('hidden.bs.collapse', function () {
    $('#preview-occasion_text').show()
    $('#preview-message').show()
  })
  $('#collapseDate').on('shown.bs.collapse', function () {
    $('#preview-delivery_date').hide()
    $('#preview-pickup_time').hide()
  })
  $('#collapseDate').on('hidden.bs.collapse', function () {
    $('#preview-delivery_date').show()
    $('#preview-pickup_time').show()
  })
}
Spree.Customization.review_payment.update = function(params, callback, error=undefined) {
  event.preventDefault()
  var link = $(event.target).closest('.card-body')

  fetch(Spree.url('/api/v1/orders/' + Spree.current_order_id + '/update_review.json'), {
    method: 'PUT',
    headers: SpreeAPI.prepareHeaders({ 'X-Spree-Order-Token': Spree.current_order_token }),
    body: JSON.stringify({
      order: params
    })
  }).then(function (response) {
    switch (response.status) {
      case 404:
        response.json().then(function (json) { window.alert(json.error) })
        break
      case 422:
        response.json().then(function (json) {
          if (error) return error(json);
          window.alert(json.error) })
        break
      case 500:
        SpreeAPI.handle500error()
        break
      case 200:
        response.json().then(function (data) {
          callback(data)
        })
        break
    }
  })
}
function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
Spree.Customization.review_payment.updateContact = function(event) {
  event.preventDefault()
  var link = $(event.target).closest('.card-body')
  var emailField = link.find('#email-field-js');
  var phoneField = link.find('#phone-field-js');
  var email = emailField.val();
  var phone = phoneField.val();
  var isValid = true;
  var callback = function(data) {
    if (data.email) { $('#preview-email').html(data.email) }
    if (data.phone) { $('#preview-phone').html(data.phone) }
    $('#collapseContact').addClass('hidden')
  }
  var error = function(json) {
    if (json.errors && json.errors.phone) {
      phoneField.closest('.form-group-validation').addClass('was-validated')
      phoneField.closest('div').children('div').removeClass("hidden");
    } else {
      alert(response.message)
    }
  }
  if (!validateEmail(email)) {
    emailField.closest('.form-group-validation').addClass('was-validated')
    emailField.closest('div').children('div').removeClass("hidden");
    isValid = false;
  } else {
    emailField.closest('div').children('div').addClass("hidden");
    emailField.closest('.form-group-validation').removeClass('was-validated')
  }
  if (!phone) {
    phoneField.closest('.form-group-validation').addClass('was-validated')
    phoneField.closest('div').children('div').removeClass("hidden");
    isValid = false;
  } else {
    phoneField.closest('div').children('div').addClass("hidden");
    phoneField.closest('.form-group-validation').removeClass('was-validated')
  }
  if (isValid) {
    Spree.Customization.review_payment.update({ email: email, phone: phone }, callback, error)
  }
}
Spree.Customization.review_payment.updateMessage = function(event) {
  event.preventDefault()
  var link = $(event.target).closest('.card-body')
  var occasion = link.find('#occasion').val();
  var message = link.find('#message').val();
  var callback = function(data) {
    if (data.occasion_text) { 
      $('#preview-occasion_text').html("Occasion: " + data.occasion_text)
    }
    if (data.message) { 
      $('#preview-message-text').html(data.message) 
    }
    $('#collapseMessage').addClass('hidden')
  }
  Spree.Customization.review_payment.update({ occasion: occasion, message: message }, callback)
}
Spree.Customization.review_payment.updateDate = function(event) {
  event.preventDefault()
  var link = $(event.target).closest('.card-body')
  var delivery_date = link.find('#js-order_delivery_date').val()
  var pickup_time = link.find('#js-pickup_time').val()
  var callback = function(data) {
    if (data.delivery_date) { $('#preview-delivery_date').html(data.delivery_date) }
    if (data.pickup_time) { $('#preview-pickup_time').html(data.pickup_time) }
    $('#collapseDate').addClass('hidden')
  }
  Spree.Customization.review_payment.update({ delivery_date: delivery_date, pickup_time: pickup_time }, callback)
}
Spree.Customization.review_payment.dateOnChange = function(event) {
  event.preventDefault()
  var link = $(event.target).closest('.card-body')
  var delivery_date = link.find('#js-order_delivery_date').val()
  var pickupSelect = link.find('#js-pickup_time')
  var callback = function(data) {
    if (data.available_pickup_time) {
      pickupSelect.empty(); // remove old options
      $.each(data.available_pickup_time, function(_, value) {
        pickupSelect.append($('<option></option>').attr('value', value[0]).text(value[1]));
      });
    }
  }
  fetch(Spree.url('/api/v1/orders/' + Spree.current_order_id + '/available_pickup_time.json' + '?delivery_date=' + delivery_date), {
    method: 'GET',
    headers: SpreeAPI.prepareHeaders({ 'X-Spree-Order-Token': Spree.current_order_token })
  }).then(function (response) {
    switch (response.status) {
      case 404:
        response.json().then(function (json) { window.alert(json.error) })
        break
      case 422:
        response.json().then(function (json) { window.alert(json.error) })
        break
      case 500:
        SpreeAPI.handle500error()
        break
      case 200:
        response.json().then(function (data) {
          callback(data)
        })
        break
    }
  })
}
